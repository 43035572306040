$primary: #55ad33;
$secondary: rgb(88, 88, 88);
@import "~bulma/bulma";
@import "~bulma-ribbon/src/sass/index.sass";
@import '~@fortawesome/fontawesome-free/css/all.min.css';

.navbar-item {
    color: $primary;
}

.navbar-item:hover {
    text-decoration: none;
    color: $secondary !important;
}